/* FIXES
--------------------------------------------------------------------------------------------*/

/* menu */
.ac_menu-2_bar_top_content .button.v_has-icon-right {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 4px;
    padding-left: 10px;
}

.ac_menu-2_bar_top {
    background: none;
    padding: 5px;
}

.ac_menu-2_logo_img {
    width: 180px;
    margin-top:-30px;
    margin-left:-13px;
}

.ac_menu-2_logo_container {
    img {
        max-width: 140%;
    }
}

.ac_menu-2_main_content {
    .button {
        font-size: 20px;
    }
}

/* Header */
[data-s-type="hero"] {
    max-height: 650px;
}

[data-s-type="hero-alt"] {
    background: linear-gradient(
        -135deg, #a90023, #dc002e) !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    max-height:150px;
    min-height: 150px;
}

[data-s-type="hero-alt"] .ac_hero_alt_slide_content_inner {
    padding-top:20px;
}

/* Toptasks */
[data-s-id="home-toptasks"] { 
    margin:5px;
}

/* Services */
[data-s-id="home-services"] {
    padding:0;
}

[data-s-type="services"] .ac_item { 
    margin-bottom: 0;
}

[data-s-type="services"] .ac_item_content_title {
    min-height: 45px;
}
.single-service {
    [data-s-type="services"] .ac_item_content_icon svg {
        width: 60px;
        height: 60px;
        stroke: #444444;
        fill: transparent;
    }
}

/* CTA */
[data-s-type="cta"] .ac_cta_column {
    padding: 0;
    width: 100%;
    text-align: center;
}

[data-s-type="cta"] .ac_cta_container {
    padding: 50px;
}

/* Footer */
[data-f-type="footer-1"] .ac_footer_primary_container {
    padding:40px 10px 20px 10px;
}

// MENU MOBILE
@media screen and (max-width: 49.9375em){
    .ac_menu-2_logo_img {
        margin-top: 0px; 
        margin-left: 0px; 
    }

    .ac_menu-2_sticky{
        display: block;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

// GENERAL
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

//FORMS

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 75vh !important;
}


/* GALLERY*/
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}